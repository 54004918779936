import * as React from "react";
import { memo } from "react";
const SvgCloseSm = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 25,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement(
    "mask",
    {
      id: "d592ba84-f99e-4f38-8d04-c0f91a2ce5f3-a",
      width: 25,
      height: 24,
      x: 0,
      y: 0,
      maskUnits: "userSpaceOnUse",
      style: {
        maskType: "alpha"
      }
    },
    /* @__PURE__ */ React.createElement("path", { fill: "#C4C4C4", d: "M.5 0h24v24H.5z" })
  ),
  /* @__PURE__ */ React.createElement(
    "g",
    {
      stroke: "#F68A1F",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: 2,
      clipPath: "url(#fd6ca343-2217-45c9-8500-30462683b987-b)"
    },
    /* @__PURE__ */ React.createElement("path", { d: "m8.5 8 7.87 7.87M16.37 8 8.5 15.87" })
  ),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "fd6ca343-2217-45c9-8500-30462683b987-b" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M7.5 7h9.87v9.87H7.5z" })))
);
const Memo = memo(SvgCloseSm);
export default Memo;
